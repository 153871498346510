import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { selectActiveType, selectProjects } from './selectors'
import BreakdownsTooltip from './BreakdownsTooltip'
import TopProjects from '../../reports/top/TopProjects'
import minutesFormatter from 'lib/minuteFormatter'

const mapStateToProps = state => ({
  data: selectProjects(state),
  type: selectActiveType(state),
  tooltipContent: BreakdownsTooltip,
  formatter: minutesFormatter
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(TopProjects)
