import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectTop, selectPzs, selectMaxTopValue } from './selectors'
import TopChart from '../../reports/top/TopChart'
import { defineMessages, intlShape, injectIntl } from 'react-intl'
import { compose } from 'redux'
import ChangeoversDurationsTooltip from './BreakdownsTooltip'
import minuteFormatter from 'lib/minuteFormatter'

const messages = defineMessages({
  title: {
    id: 'oee.locationBreakdownsTop.title',
    defaultMessage: 'TOP 10 Stoppage Breakdowns'
  }
})

const BreakdownsTop = ({ data, pzs, maxValue, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {Object.entries(data).map(([month, dataSet]) => (
          <TopChart
            key={month}
            month={month}
            pz={pzs[month]}
            data={dataSet}
            maxValue={maxValue}
            tooltipContent={ChangeoversDurationsTooltip}
            formatter={minuteFormatter}
          />
        ))}
      </div>
    </div>
  )
}

BreakdownsTop.propTypes = {
  data: PropTypes.object,
  maxValue: PropTypes.number.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectTop(state),
  pzs: selectPzs(state),
  maxValue: selectMaxTopValue(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(BreakdownsTop)
