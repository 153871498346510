import React from 'react'
import PropTypes from 'prop-types'
import Duration from 'components/Duration'
import { FormattedMessage } from 'react-intl'

const ChangeoversDurationsTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const data = payload[0]
    const { symbol, label, percent } = data.payload
    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>
          {symbol ? `${symbol} - ${label}` : `${label}`}
        </div>

        <div className='recharts-custom-tooltip__value'>
          <table>
            <tbody>
              <tr>
                <td>
                  <b>
                    <FormattedMessage id='oee.locationBreakdownsTooltip.duration' />:
                  </b>
                </td>
                <td>
                  <Duration noNbsp minutes={payload[0].value} />
                </td>
              </tr>
              <tr>
                <td>
                  <b>
                    <FormattedMessage id='oee.locationBreakdownsTooltip.percent' />:
                  </b>
                </td>
                <td>{percent ? `${percent.toFixed(2)}%` : '---'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  return null
}

ChangeoversDurationsTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ),
  label: PropTypes.string.isRequired
}

export default ChangeoversDurationsTooltip
